import { bindable, inject }        from 'aurelia-framework';
import { I18N }                    from 'aurelia-i18n';
import { BaseListViewModel }       from 'base-list-view-model';
import { FilterFormSchema }        from 'modules/management/dynamic-fields/material-types/filter-form-schema';
import { AppContainer }            from 'resources/services/app-container';
import { Downloader }              from 'resources/services/downloader';
import { MaterialTypesRepository } from './services/repository';

@inject(AppContainer, MaterialTypesRepository, I18N, Downloader, FilterFormSchema)
export class ListMaterialTypes extends BaseListViewModel {

    listingId = 'management-material-types-listing';

    @bindable headerTitle    = 'listing.management.dynamic-fields.material-types';
    @bindable newRecordRoute = 'management.dynamic-fields.material-types.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     */
    constructor(appContainer, repository, i18N, downloader, filterFormSchema) {
        super(appContainer);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.dynamic-fields.material-types.manage',
            'management.dynamic-fields.material-types.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      this.repository,
            show:            {
                action:  (row) => this.appContainer.router.navigateToRoute('management.dynamic-fields.material-types.view', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.dynamic-fields.material-types.manage', 'management.dynamic-fields.material-types.view']),
            },
            edit:            {
                action:  (row) => this.appContainer.router.navigateToRoute('management.dynamic-fields.material-types.edit', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.dynamic-fields.material-types.manage', 'management.dynamic-fields.material-types.edit']),
            },
            destroy:         {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.dynamic-fields.material-types.manage', 'management.dynamic-fields.material-types.delete']),
            },
            options:         [
                {
                    label:   'form.button.export-to-excel',
                    icon:    'icon-file-excel',
                    action:  () => this.exportListing(this.i18N.tr('form.field.material-types'), !this.datatable.instance.selectedRows.length ? null : this.datatable.instance.selectedRows),
                    visible: (row) => this.appContainer.authenticatedUser.can(['management.dynamic-fields.material-types.manage', 'management.dynamic-fields.material-types.view']),
                },
            ],
            selectable:      true,
            destroySelected: true,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'name',
                    name:  'material_type_translations.name',
                    title: 'form.field.designation',
                },
                {
                    data:  'created_by',
                    name:  'users.name',
                    title: 'form.field.created-by',
                },
                {
                    data:            'created_at',
                    name:            'material_types.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

}
